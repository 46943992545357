<template>

  <div class="shadow-2xl rounded-3xl w-full mx-auto">
    <div class="intro-y box col-span-2">


      <div class="container " style="margin: 0 1rem; padding: 0 1rem; border: 1px solid #d8d6de; border-radius: 0.357rem;">
        <p>Select Date range </p>
        
        <div class="form-group" style="display: inline-block; margin-right: 1rem;">
          <label for="fromDate">From:</label>
          <input required v-model="from_date" type="date" class="form-control" id="fromDate">
        </div>
        <div class="form-group" style="display: inline-block; margin-right: 1rem;">
          <label for="toDate">To:</label>
          <input required v-model="to_date" type="date" class="form-control" id="toDate">
        </div>
        <div class="form-group" style="display: inline-block; margin-right: 1rem;">
          <label for="payment_status">Select Payment Method:</label>
          <select required v-model="payment_method" class="form-control" id="payment_method" >
            <!-- <option value="" disabled selected>Select Status</option> -->
            <option value="0">All</option>
            <option value="1">cash</option>
            <option value="2">Gateway</option>
          </select>
        </div>
        <div class="form-group" style="display: inline-block; margin-right: 1rem;">
          <label for="payment_status">Select Status:</label>
          <select required v-model="payment_status" class="form-control" id="payment_status" >
            <!-- <option value="" disabled selected>Select Status</option> -->
            <option value="1">Paid</option>
            <option value="0">Unpaid</option>
          </select>
        </div>
        <button @click.prevent="SearchinvoiceListData" class="btn btn-primary"  style=" margin-right: 1rem; border-color: #00cfe8 !important; background-color: #00cfe8 !important;">Submit</button>
        
      </div>
      
      <h4 >Total Invoice : {{total_invoice}}   Total Bill : {{ total }}</h4>
      <table class="w-full border border-gray-300">
        <thead>
          <tr>
            <th class="pl-2 border-b border-l border-r border-gray-300 text-center">SL</th>
            <th class="pl-2 border-b border-l border-r border-gray-300 text-center">Paid date</th>
            <th class="pl-2 border-b border-l border-r border-gray-300 text-center">Invoice date</th>
            <th v-if="payment_status == 0" class="pl-2 border-b border-l border-r border-gray-300 text-center">Last Payment date</th>
            <th class="pl-2 border-b border-l border-r border-gray-300 text-center">Invoice ID</th>
            <th class="pl-2 border-b border-l border-r border-gray-300 text-center">User Name</th>
            <th class="pl-2 border-b border-l border-r border-gray-300 text-center">Mobile</th>
            <th class="pl-2 border-b border-l border-r border-gray-300 text-center">Amount</th>
            <th class="pl-2 border-b border-l border-r border-gray-300 text-center">Payment Method</th>
            <th class="pl-2 border-b border-l border-r border-gray-300 text-center">Last App login</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(invoice, index) in this.invoices" :key="index" class="bg-gray-100">
          <!-- <tr class="bg-gray-100"> -->
          <!-- <tr v-for="(invoice, index) in invoices" :key="index" class="bg-gray-100"> -->
            <td class="pl-2 border-b border-l border-r border-gray-300">{{ index+1 }}</td>
            <td class="pl-2 border-b border-l border-r border-gray-300">
              {{ invoice.paid_date ? invoice.paid_date : 'N/A' }}
            </td>
            <td class="pl-2 border-b border-l border-r border-gray-300">
              {{invoice.invoice_date}}
            </td>
            <td  v-if="payment_status == 0"  class="pl-2 border-b border-l border-r border-gray-300">
              {{invoice.last_payment_date}}
            </td>
            
            <td  v-if="invoice.id" class="pl-2 border-b border-l border-r border-gray-300">
              <router-link
                :to="{ name: 'isp-invoice-preview', params: { invoiceId: invoice.id }}"
                target="_blank"
                class="font-weight-bold"
              >
                {{ invoice.invoice_code }}
              </router-link>
                
            </td>   
            <td class="pl-2 border-b border-l border-r border-gray-300 ">
              {{invoice.isp_user_name}}
            </td>
            <td class="pl-2 border-b border-l border-r border-gray-300 ">
              +{{invoice.mobile}}
            </td>
            <td class="pl-2 border-b border-l border-r border-gray-300 text-center">
              {{invoice.amount}}
            </td>
            <td class="pl-2 border-b border-l border-r border-gray-300 text-center">
              
              {{ invoice.payment_method === 1 ? 'Cash' : invoice.payment_method === 2 ? 'Gateway' : 'N/A' }}
            </td>
            <td class="pl-2 border-b border-l border-r border-gray-300 text-center">
              {{invoice.last_app_hit}}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>

import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import {
BAvatar,
BPagination,
BFormGroup,
BFormInput,
BFormSelect,
BRow,
BCol,
BLink,
BTooltip,
BMedia,
BBadge,
BButton,
VBToggle,
BSidebar,
BForm,
BFormTextarea,
} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { VueGoodTable } from "vue-good-table";
// import store from '@/store/index'
import store from "@/store";
import Ripple from "vue-ripple-directive";
import flatPickr from "vue-flatpickr-component";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import useJwt from "@/auth/jwt/useJwt";
import vSelect from "vue-select";
import { required, email } from "@validations";
import { checkRes } from "@core/services/helper";
// Use toast
import Mixin from "@core/services/mixin";


import { ISP_LIST, DATE_WISE_REPORT,DATE_WISE_REPORT_NEW, TEST} from "@core/services/api";
  export default {
    mixins: [Mixin],
directives: {
  Ripple,
  "b-toggle": VBToggle,
},
components: {
  BCardCode,
  VueGoodTable,
  BAvatar,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BRow,
  BCol,
  BLink,
  BTooltip,
  BMedia,
  BBadge,
  BButton,
  BSidebar,
  BForm,
  BFormTextarea,
  ValidationProvider,
  ValidationObserver,
  vSelect,
  flatPickr,
  // eslint-disable-next-line vue/no-unused-components
  ToastificationContent,
},
data() {
  // const statusOptions = [
  //   { value: 2, label: "Paid" },
  //   { value: 1, label: "Unpaid" },
  //   { value: 0, label: "Draft" },
  // ];
  // const resolveClientAvatarVariant = (status) => {
  //   if (status === "Partial Payment") return "primary";
  //   if (status === "Paid") return "danger";
  //   if (status === "Downloaded") return "secondary";
  //   if (status === "Draft") return "warning";
  //   if (status === "Sent") return "info";
  //   if (status === "Past Due") return "success";
  //   return "primary";
  // };
      return {
        isp_id:JSON.parse(localStorage.getItem('userData')),
        name: null,
        price: null,
        total: 0,
        total_invoice : 0,
        invoices: [],
        test: [],
        isp_id:JSON.parse(localStorage.getItem('userData')),
        from_date :"",
        to_date :"",
        payment_status: null,
        payment_method: null,
      }
    },
    methods: {
          SearchinvoiceListData() {
          this.$http
              .get(process.env.VUE_APP_BASEURL + DATE_WISE_REPORT, {
              params: {
                from_date: this.from_date,
                to_date: this.to_date,
                isp_id: this.isp_id.id,
                payment_status : this.payment_status,
                payment_method : this.payment_method,
              },
            })
              .then((res) => {
              console.log('Invoice data:',res.data); 
              console.log('hi');
              this.invoices =res.data.data.invoices;
              this.total =res.data.data.total;
              this.total_invoice =res.data.data.total_invoice;
              
              })
              .catch((error) => {
              if (error.response.payment_status === 404) {
                  userData.value = undefined;
              }
              });
          },
          
      },
  }
</script>
<style lang="scss" >
@import "@core/scss/vue/libs/vue-good-table.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";


</style>
  <style scoped>
  .error-message {
    color: red;
  }
  </style>